import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel } from 'Atoms';
import { pluralizeWord } from 'Client/utils/stringManipulations';
import { MultiselectOption } from 'Shared/types/question';
import { Checkbox } from '../Checkbox';
import { WhiteChevron } from '../MapInfoPanel/components/ToMapButton.styles';
import {
  Wrapper,
  OptionsSection,
  OpenButton,
  OptionItem,
} from './ExpandableMultiselect.styles';

interface ExpandableMultiselectProps {
  title: string;
  name?: string;
  options: MultiselectOption[];
  rounded?: boolean;
  onChange: (option: MultiselectOption[]) => void;
  value?: MultiselectOption[];
  showFullWidth?: boolean;
  onClick?: () => void;
}
export const ExpandableMultiselect = ({
  title,
  options,
  name,
  rounded = true,
  onChange,
  value = [],
  showFullWidth = true,
  ...props
}: ExpandableMultiselectProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  const initialOpts = value.length
    ? options.map((opt) => ({
        ...opt,
        checked: value.find((v) => v.value === opt.value)?.checked || false,
      }))
    : options;

  const wrapperRef = React.useRef(null);
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (value: string) => {
    const newOptions = initialOpts.map((opt) => {
      if (opt.value === value) {
        return { ...opt, checked: !opt.checked };
      }
      return opt;
    });

    return onChange(newOptions);
  };

  const selectedItems = (initialOpts || []).filter((option) => option.checked);

  const expandedLabel = `${selectedItems.length}
    ${
      name
        ? pluralizeWord(name, selectedItems.length)
        : pluralizeWord('item', selectedItems.length)
    }`;

  const showCustomTitle = expanded || (!expanded && !!selectedItems.length);
  const handleSelectAll = (value: boolean) => {
    onChange(initialOpts.map((opt) => ({ ...opt, checked: value })));
    setSelectAll(value);
  };

  return (
    <Wrapper ref={wrapperRef} {...props}>
      <OpenButton
        aria-pressed={expanded}
        aria-expanded={expanded}
        rounded={rounded}
        onClick={() => setExpanded(!expanded)}
      >
        <InputLabel>{showCustomTitle ? expandedLabel : title}</InputLabel>
        <WhiteChevron
          color="black"
          width={15}
          height={11.6}
          style={{ transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)' }}
        />
      </OpenButton>

      {expanded && (
        <OptionsSection
          aria-expanded={expanded}
          showFullWidth={showFullWidth}
          data-testid="expandable-multiselect-options-section"
        >
          <OptionItem
            key="select-all"
            onClick={() => handleSelectAll(!selectAll)}
          >
            <Checkbox checked={selectAll} id={`${title}-select-all`} />
            <label htmlFor={'select-all'}>{t('Select all')}</label>
          </OptionItem>
          {initialOpts.map((opt) => (
            <OptionItem key={opt.value} onClick={() => handleChange(opt.value)}>
              <Checkbox checked={opt.checked} id={opt.value} />
              <label htmlFor={opt.value}>
                {opt?.icon}
                {opt.label}
              </label>
            </OptionItem>
          ))}
        </OptionsSection>
      )}
    </Wrapper>
  );
};
